import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { localeDate } from '~utils/dates';
import Link from '~components/Link';
import Excerpt from '~components/Excerpt';
import { formatLengthInSeconds } from '~utils/common';
import Tooltip from '@material-ui/core/Tooltip';
import WhiteCheckbox from '~src/assets/images/other/white-checkbox.svg';

const useStyles = makeStyles(theme => ({
  wrapper: {
    fontSize: '12px',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '413px',
      fontSize: '10px',
      marginBottom: '60px'
    }
  },
  lastViewedDate: { fontSize: '1.5em', lineHeight: '1.7em', fontWeight: 'bold' },
  thumbnailAndDetailsWrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  thumbnailLink: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    marginRight: '23px',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: '6px'
    }
  },
  thumbnail: { display: 'block' },
  progressBarWrapper: { height: '6px', backgroundColor: '#D9D9D9' },
  progressBar: { backgroundColor: '#EB0000', height: '6px' },
  detailsWrapper: {},
  videoTitleLink: {
    display: 'block',
    fontSize: '2em',
    fontWeight: '500',
    color: 'black',
    lineHeight: '1.172083333333333em'
  },
  playlistTitleLink: {
    display: 'block',
    fontSize: '1.66666666em',
    lineHeight: '1.172em',
    color: 'black',
    marginTop: '6px'
  },
  releaseDateWrapper: {
    fontSize: '1em',
    lineHeight: '1.171666666666667em',
    color: 'rgba(0, 0, 0, 0.7)',
    marginTop: '0.5em',
    display: 'flex'
  },
  releaseDateSeparator: {
    margin: '0 0.75em'
  },
  seriesPositionLabel: {
    fontWeight: 500
  },
  authorName: {
    marginTop: '1em',
    fontSize: '1.333333333333333em',
    lineHeight: '1.171875em',
    color: 'black'
  },
  authorRole: {
    marginTop: '4px',
    fontSize: '1.166666666666667em',
    lineHeight: '1.172142857142857em',
    color: 'black'
  },
  videoDescription: {
    marginTop: '1.333333333333333em',
    fontSize: '1.166666666666667em',
    lineHeight: '1.171875em',
    color: 'black'
  },
  videoLengthIndicator: {
    display: 'block',
    position: 'absolute',
    bottom: '14px',
    right: '10px',
    padding: '4px 5.5px',
    borderRadius: '2px',
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center'
  },
  isWatchedIndicator: {
    display: 'block',
    position: 'absolute',
    top: '8px',
    right: '12px',
    width: '32px',
    height: '32px',
    background: 'transparent',
    color: 'white',
    fontSize: '32px'
  },
  isWatchedIndicatorTooltip: {
    color: 'white',
    fontSize: '12px',
    lineHeight: '14.52px'
  }
}));
// eslint-disable-next-line import/prefer-default-export
export const VideoViewPanel = ({
  latestViewDate,
  video,
  playlist,
  author,
  progressRate,
  isWatched,
  thumbnailUrl
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.lastViewedDate}>
        {`Last viewed: ${localeDate(latestViewDate, {
          year: 'numeric',
          day: 'numeric',
          month: 'short'
        })}`}
      </div>
      <div className={classes.thumbnailAndDetailsWrapper}>
        <Link
          className={classes.thumbnailLink}
          to={video.url}
          state={{
            index: video.seriesPosition - 1,
            seekToSecond: video.lengthInSeconds * progressRate
          }}
        >
          <img className={classes.thumbnail} src={thumbnailUrl} alt="" width={413} height={234} />
          <div className={classes.progressBarWrapper}>
            <div className={classes.progressBar} style={{ width: `${progressRate * 100}%` }}>
              &nbsp;
            </div>
          </div>
          <span className={classes.videoLengthIndicator}>
            {formatLengthInSeconds(video.lengthInSeconds)}
          </span>
          {isWatched && (
            <Tooltip
              title={
                <div className={classes.isWatchedIndicatorTooltip}>
                  You’ve completed this video.
                </div>
              }
              arrow
              placement="bottom"
            >
              <span className={classes.isWatchedIndicator}>
                <img src={WhiteCheckbox} width={32} height={32} alt="yes" />
              </span>
            </Tooltip>
          )}
        </Link>
        <div className={classes.detailsWrapper}>
          <Link className={classes.videoTitleLink} to={video.url}>
            {video.title}
          </Link>
          <Link className={classes.playlistTitleLink} to={playlist.url}>
            {playlist.title}
          </Link>
          <div className={classes.releaseDateWrapper}>
            <span>{`Release Date: ${localeDate(video.releaseDate, {
              day: 'numeric',
              year: 'numeric',
              month: 'short'
            })}`}</span>
            {playlist.seriesLength > 1 && <span className={classes.releaseDateSeparator}>|</span>}
            {playlist.seriesLength > 1 && (
              <span>
                <span className={classes.seriesPositionLabel}>Series</span>
                &nbsp;Part&nbsp;{video.seriesPosition}&nbsp;of&nbsp;{playlist.seriesLength}
              </span>
            )}
          </div>
          <div className={classes.authorName}>{author.name}</div>
          <div className={classes.authorRole}>{author.role}</div>
          <Excerpt
            className={classes.videoDescription}
            description={String(video.description)}
            buttons={false}
            lines={2}
          />
        </div>
      </div>
    </div>
  );
};
// See `WatchHistoryElement` in `backend/src/utils/enrichVideoViewWithPrismicData.ts:7`.
VideoViewPanel.propTypes = {
  latestViewDate: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  progressRate: PropTypes.number,
  isWatched: PropTypes.bool,
  video: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    seriesPosition: PropTypes.number,
    releaseDate: PropTypes.string,
    description: PropTypes.string,
    lengthInSeconds: PropTypes.number
  }),
  playlist: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    seriesLength: PropTypes.number
  }),
  author: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string
  })
};
VideoViewPanel.defaultProps = {
  latestViewDate: null,
  thumbnailUrl: '',
  progressRate: 0,
  isWatched: false,
  video: {},
  playlist: {},
  author: {}
};
