import React from 'react';

import Page from '~layout/Page';
import ViewHistoryLayout from '~modules/account/components/ViewHistoryLayout';

const WatchHistoryPage = () => (
  <Page>
    <ViewHistoryLayout />
  </Page>
);

export default WatchHistoryPage;
