import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

import Container from '~src/components/Container';
import LoadingSpinner from '~src/components/LoadingSpinner';
import useApi, { USER_VIEW_HISTORY } from '~src/hooks/useApi';
import { VideoViewPanel } from './VideoViewPanel';

const ViewHistoryLayout = () => {
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error } = state;

  useEffect(() => {
    makeRequest(USER_VIEW_HISTORY());
  }, [makeRequest]);

  return (
    <Container maxWidth="md">
      <Typography style={{ marginBottom: '28px' }} variant="h4">
        Watch History
      </Typography>
      {isLoading && (
        <>
          <Typography variant="body1">Just a moment while we update your watch history</Typography>
          <LoadingSpinner />
        </>
      )}
      {isError && error && <strong>Error: {error}</strong>}
      {data && data.map(element => <VideoViewPanel key={element.video.url} {...element} />)}
    </Container>
  );
};

export default ViewHistoryLayout;
