import { useEffect } from 'react';

/**
 * react-hook-form does not work natively with some controlled components like `<TextInput select>` from the Material UI.
 * This custom hook makes required preparations to include the given named controlled component (JUST ONE component)
 * into the react-hook-form setup so the value of this component will become included in validation and `onSubmit` arguments.
 * @see https://react-hook-form.com/advanced-usage/#ControlledmixedwithUncontrolledComponents
 * @see https://codesandbox.io/s/react-hook-form-controlled-mixed-with-uncontrolled-n30nu
 *
 * @param {{ name: string, register: function, watch: function, setValue: function }} config
 *        Name is the HTML `name` attr of the component to prepare
 *        `register`, `watch` and `setValue` are functions directly taken from the result of calling `useForm()`
 * @returns {[value: string, setValue: function]} `value` is the current value of the element on this render. `setValue` is an `onChange` handler to be attached to the component.
 */
export const useFormWithControlledComponent = ({ name, register, watch, setValue }) => {
  const controlValue = watch(name);
  useEffect(() => {
    register({ name });
  }, [name, register]);
  const onControlValueChange = ({ target }) => setValue(name, target.value, true);

  return [controlValue, onControlValueChange];
};

export default Error('This module does not have default export');

export const formatLengthInSeconds = input => {
  if (typeof input !== 'number' || input < 0 || Number.isNaN(input)) {
    return '';
  }
  const hours = Math.floor(input / 3600);
  // NOTE: This is an arbitrary limit to prevent using numbers that are too large
  if (hours > 99) {
    return '';
  }
  const leftoverSecondsInHour = input % 3600;
  const minutes = Math.floor(leftoverSecondsInHour / 60);
  const leftoverSecondsInMinute = leftoverSecondsInHour % 60;

  const hoursString = hours ? `${hours}` : undefined;
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsString =
    leftoverSecondsInMinute < 10 ? `0${leftoverSecondsInMinute}` : `${leftoverSecondsInMinute}`;
  return [hoursString, minutesString, secondsString].filter(Boolean).join(':');
};
